
#email_form{
  display: block;
  height: 250px; /*same height as header*/
  margin-top: -250px; /*same height as header*/
  visibility: hidden;
}

/*** ADJUSTER PAGE ***/

.adjuster_detail .team_column ul {
  margin-left: 3em;
}
.adjuster_detail .team_column ul li {
  list-style: disc;
}
.adjuster_detail .attorney-img img {
  padding: 12px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

/*** SERVICES PAGE ***/

.practice-areas .practice-img img {
  padding: 4px;
  border: 1px solid rgb(230, 230, 230);
}